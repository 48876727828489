<template>
    <div>
        <Head :title="'工序任务'" :out="'guide'" />
        <!-- @rendered="tabsChange" @click="tabsChange" -->
      
        <van-tabs class="vanTabs" v-model="active" @click="ChangeHan" >
            <van-tab title="任务大厅"><taskList ref="taskRef" @goNext="goNext" /></van-tab>
            <van-tab title="待开始任务"><ToStart ref="StartRef" @goNext="goNext" /></van-tab>
            <van-tab title="待完成任务"><WithComplete ref="CompleteRef" @goNext="goNext" /></van-tab>
            <van-tab title="已完成任务"><Accomplish ref="lishRef" @goNext="goNext" /></van-tab>
        </van-tabs>
          
    </div>
</template>

<script>
import Head from '@/components/Head'
import taskList from './taskList.vue'
import ToStart from './ToStart.vue'
import WithComplete from './WithComplete.vue'
import Accomplish from './Accomplish.vue'
import {Toast} from 'vant'
    export default {
        components: {
            Head,taskList,ToStart,WithComplete,Accomplish
        },
        data() {
            return {
                active:0,
              
            }
        },
        mounted () {
            if(this.$route.query.active == 0){
                this.active = 0
            }else if(this.$route.query.active == 1){
                this.active = 1
            }else  if(this.$route.query.active == 2){
                this.active = 2
            }else  if(this.$route.query.active == 3){
                this.active = 3

            }
            this.ChangeHan()
        },
        methods: {
            goNext(e){
                this.active =e
                this.ChangeHan()
            },
            ChangeHan(){
                if(this.active == 0){
                    this.$nextTick(()=>{
                        this.$refs.taskRef.lodData()
                    })
                }else if(this.active == 1){
                    this.$nextTick(()=>{
                        this.$refs.StartRef.lodData()
                    })
                }else if(this.active == 2){
                    this.$nextTick(()=>{
                        this.$refs.CompleteRef.lodData()
                    })
                } else if(this.active == 3){
                    this.$nextTick(()=>{
                        this.$refs.lishRef.lodData()
                        // this.$refs.lishRef.onLoad()
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.vanTabs{
    margin-top: 55px;
}
</style>