<template>
<!-- 任务大厅 -->
    <div class="warper">
        <van-search
            v-model="value"
            show-action
           
            placeholder="请输入搜索关键词"
            @search="onSearch"
            >
            <select v-model="label" slot="label">
                <option value="carNumberPlate">车牌号：</option>
                <option value="defined_num">自定义编号：</option>
            </select>

            <template #action>
                <div @click="onSearch">搜索</div>
            </template>
        </van-search>
       
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item">
                <p>{{item.createTime}}</p>
                <div class="info">
                    <img style="width:100px;height:100px;flex:1" :src="item.vehicleImages0?item.vehicleImages0:imgs" alt="">
                    <div style="flex:2;margin-left: 10px">
                        <p class="tit">{{item.working_procedure_name}}</p>
                        <p style="font-weight: 700;">自定义编号：{{item.defined_num}}</p>
                        <p>车牌号：{{item.carNumberPlate}}</p>
                        <p><span>绩效统计维度：</span>{{item.working_weidoo_name}}</p>
                    </div>
                </div>
                <p v-if="item.remark">
                    <span>工序备注：</span>
                    {{item.remark}}
                </p>
                <p v-if="item.dismantle_allocation_remark">
                    <span>拆解要求：</span>
                    {{item.dismantle_allocation_remark}}
                </p>
                <p class="btn-p">
                    <van-button type="info" style="width:200px" @click="orderChange(item.id)">接单</van-button>
                </p>
            </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
import {Toast,Dialog} from 'vant'
import { taskList,takeOrders } from '@/api/gong'
    export default {
        data() {
            return {
                refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                dismantle_allocation_remark:"",
                list:[],
                page:0,
                pageSize:10,
                imgs:require('../../assets/zw.jpg'),
                value:'',
                label:'carNumberPlate',
            }
        },
        methods: {
            onSearch(){
                this.list = [] 
                this.page = 0
               this.onLoad()
            },
            orderChange(id){
                Dialog.confirm({
                    title: '提示',
                    message: '是否接单？',
                    })
                    .then(async () => {
                        // on confirm
                        try{
                            const {data} = await takeOrders({id}).then(res=>res)
                            this.$toast.success(data.msg)
                            // this.page = 0
                            // this.lodData()
                            this.$emit('goNext',1)
                        }
                        catch{

                        }
                       
                    })
                    .catch(() => {
                        // on cancel
                    });
            },
             //加载 上拉
            onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                // this.onLoad();
            },
            lodData(){
                 this.list = [] 
                 this.page = 0
                 this.onLoad()
            },
            //下拉
            onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async () => {
                    try{
                        let page = this_.page + 1
                        this_.page = page
                        let pageSize = this_.pageSize
                        let obj={
                            pageSize,page,
                            key:this.label,
                            value:this.value
                        }
                        const {data} = await taskList(obj).then(res=>res)
                        toast.clear();
                        console.log(data.data.data)
                        if(data.data.data.length==0){
                            //this_.list = []
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            
                            let arr = data.data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                }, 1000);

            },
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    height: 70vh;
    padding: 10px;
    .content-infp{
        margin-top: 10px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #eee;
        .info{
            display: flex;
            .tit{
                font-size: 23px;
                font-weight: 700;
            }
             p{
                margin: 10px 0;
            }  
        }
        .btn-p{
            text-align: center;
        }
    }
}
</style>